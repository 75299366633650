import { func } from 'prop-types';
import React, { useEffect, useState } from 'react'
import './FilterGeneric.css'
import { useDispatch, useSelector } from 'react-redux';
import { setFilterChangedId } from '../../services/store/GenericReducer';


export default function FilterGeneric(props) {

    const store = useSelector(state => state.reducer)
    const [showDropDown, setShowDropDown] = useState(false)
    const [filterValue, setFilterValue] = useState(false)
    const [filterDisplay, setFilterDisplay] = useState(props.apiFilter)
    const dispatch = useDispatch();

    useEffect(() => {
        filterValue && setFilterDisplay(filterValue)
        if(!filterValue && store.filterChangedId.includes(props.apiFilter.filterTypeID)){
            let index = store.filterChangedId.indexOf(props.apiFilter.filterTypeID);
            if (index > -1) {
                let newArray = [...store.filterChangedId];
                newArray.splice(index, 1);
                dispatch(setFilterChangedId(newArray))
            }
        }
        props.apiFilter.filterTypeID && props.updateFilter(props.apiFilter.filterTypeID, filterValue)
    }, [filterValue])

    useEffect(() => {
        store.filterChoices[props.apiFilter.filterTypeID] && setFilterValue(store.filterChoices[props.apiFilter.filterTypeID]?.choice)
    }, [])

    useEffect(() => {
        props.clearFilterLabel > 0 && setFilterDisplay(props.apiFilter)
        props.clearFilterLabel > 0 && setFilterValue(false)
    }, [props.clearFilterLabel])

    return (
        <div onClick={() => setShowDropDown(!showDropDown)} className={`field-wrapper dropdown ${props.iconDiff && 'filter-fields-dropdown'} ${showDropDown && 'show'}`}> 
            <label htmlFor="filter-type" className="sr-only">{props.apiFilter.title}</label>
            <div className="input-with-icon"> 
                {props.children}
                <input type="text" id="filter-type" className={filterValue ? "text-blue" : ""} placeholder={filterDisplay.title} readOnly={true}/> 
                <span className="dropdown-arrow"></span> 
            </div>
            <div className="dropdown-content">
                <div className="list-container">
                    <ul>
                        <li onClick={() => {setFilterDisplay(props.apiFilter); setFilterValue(false)}}> X נקה בחירה</li>
                        {
                            props.filter?.map((item, index) => {
                                return <li key={index} onClick={() => setFilterValue(item)} data-param-name="filterFieldDisplay"> <a>{item.title}</a> </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
