import React, { useEffect, useState } from 'react'
import H1Title from '../h1-title/H1Title'
import './Hero.css'
import FilterLocation from "../filter-location/FilterLocation"
import { buildFiltersFromProjects, filterProjects, isEmpty } from '../../services/filters/filters'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilterChangedId,
  setFilterChoices,
  setSeasonalFilterRdx,
  setSrcFilters,
  setTempFilters
} from '../../services/store/GenericReducer'
import FilterGeneric from '../filter-generic/FilterGeneric'
import PeopleIcon from '../../assets/images/svgComp/PeopleIcon'
import CommitmentIcon from '../../assets/images/svgComp/CommitmentIcon'
import PopulationIcon from '../../assets/images/svgComp/PopulationIcon'
import CampusIcon from '../../assets/images/svgComp/CampusIcon'
import { isJsonStr } from '../../services/general-utils/generalUtils'
import FilterAmount from '../filter-amount/FilterAmount'
import AmountIcon from '../../assets/images/svgComp/AmountIcon'



export default function Hero(props) {

  const store = useSelector(state => state.reducer)
  const dispatch = useDispatch()
  const [clearFilterLabel, setClearFilterLabel] = useState(0)

  useEffect(() => {
      init()
  }, [store.partner])

  const init = () => {
    if(!store.filterChoices){
      let filterChoices = {}
      Array.isArray(props.filters) && props.filters.map(item => {
        let specialFilterTitle = ""
        if(item.filterTypeID === 11){
          let filterDetails = item.filterTypeID === 11 && isJsonStr(item.filterDetails) && JSON.parse(item.filterDetails)
          specialFilterTitle = Array.isArray(filterDetails.filterKeyValues) && 
              filterDetails.filterKeyValues.length > 1 &&
              filterDetails.filterKeyValues[1].Value
        }
        
        filterChoices[item.filterTypeID] = {
          filterTypeID: item.filterTypeID,
          title: item.filterTypeID === 11 && specialFilterTitle ? specialFilterTitle : item.title,
          choice: false
        }
      })
      dispatch(setFilterChoices(filterChoices))
    }
    let filters = !isEmpty(store.partner) && store.allProjects && setFiltersFromProjects(store.allProjects, props.filters, store, dispatch)
    if(!isEmpty(filters) && !isEmpty(store.partner)){
      dispatch(setTempFilters(filters))
      dispatch(setSrcFilters(filters))
    }
  }

  const setFiltersFromProjects = (projects, apiFilters, store, dispatch) => {
      return buildFiltersFromProjects(projects, apiFilters, store, dispatch)
  }

  const updateFilterChoices = (filterTypeID, choice) => {
    if(filterTypeID && store.filterChoices){
      let filtersObj = {...store.filterChoices}
      let final = {...filtersObj, [filterTypeID]: {...filtersObj[filterTypeID], choice: choice}}
      dispatch(setFilterChoices(final))
      choice && !store.filterChangedId.includes(filterTypeID) && dispatch(setFilterChangedId([...store.filterChangedId, filterTypeID]))
    }
  }

  const clearFilters = () => {
    let filtersObj = {...store.filterChoices}
    Object.keys(filtersObj).forEach(function(key, index) {
      if(filtersObj[key].choice){
        let x = {...filtersObj[key], choice: false}
        filtersObj[key] = x
      }
    })
    dispatch(setFilterChoices(filtersObj))
    dispatch(setFilterChangedId([]))
    setClearFilterLabel(prev => prev + 1)
  }

  const setSeasonalFilter = (e) => {
    let filterValue = {...store.seasonalFilter, value: e.target.checked}
    e.target && dispatch(setSeasonalFilterRdx(filterValue))
    if(store.filterChoices){
      let filtersObj = {...store.filterChoices}
      let final = {...filtersObj, 555: {...filtersObj[555], filterTypeID: 555, title: "חיפוש", choice: filterValue.value}}
      dispatch(setFilterChoices(final))
    }
  }

  useEffect(() => {
    let filters = !isEmpty(store.partner) && store.displayProjects && setFiltersFromProjects(store.displayProjects, props.filters, store, dispatch)
    if(!isEmpty(filters) && !isEmpty(store.partner)){
      dispatch(setTempFilters(filters))
      dispatch(setSrcFilters(filters))
    }
  }, [store.displayProjects])

  useEffect(() => {
    store.filterChoices && store.partner && 'partnerConfig' in store.partner && filterProjects(store, dispatch)
  }, [store.filterChoices, store.partner])

  return (
    <section className="main-section" id="first-content-section" style={{backgroundImage: `url(${props.partner.partnerConfig.heroDesktopUrl})`}}>
        <div className="content-container">
            <H1Title title={props.partner.partnerConfig.heroTitle ? props.partner.partnerConfig.heroTitle : "התנדבות בהתאמה אישית לעובדי בנק הפועלים"}/>
            <h2>{props.partner.partnerConfig.heroSubTitle}<br/>{props.partner.partnerConfig.heroMessage}</h2>
            <form action="">
                <h3>{props.partner.partnerConfig.findProjectMessage}</h3>
                {store.seasonalFilter.display && <label htmlFor="checkbox" className="checkbox-slider">
                    {store.filterChoices.hasOwnProperty(11) && store.filterChoices[11]?.title}
                    <input type="checkbox" id="checkbox" name="filter" checked={store.seasonalFilter.value} onChange={(e) => setSeasonalFilter(e)} value="התנדבות לקיץ" data-param-name="filter" data-param-value="התנדבות לקיץ"/>
                    <div className="checkbox-rail"><span></span></div>
                </label>}
                <div className="search-fields-wrapper">
                    {
                        store.tempFilters && props.filters?.map((item, i) => {                                         
                            switch(item.filterTypeID) {
                                case 6:
                                  return <FilterGeneric key={item.filterTypeID} updateFilter={updateFilterChoices} filter={store.tempFilters.volunteerFields} apiFilter={item} iconDiff={true} clearFilterLabel={clearFilterLabel}><PeopleIcon/></FilterGeneric>  // done !!!                                                       
                                case 1:
                                  return <FilterLocation key={item.filterTypeID} updateFilter={updateFilterChoices} filter={store.tempFilters.city} apiFilter={item} clearFilterLabel={clearFilterLabel}></FilterLocation>  // done !!!
                                case 9:
                                  return <FilterGeneric key={item.filterTypeID} updateFilter={updateFilterChoices} filter={store.tempFilters.commitment} apiFilter={item} clearFilterLabel={clearFilterLabel}><CommitmentIcon/></FilterGeneric>  // done !!! 
                                case 7:
                                  return <FilterGeneric key={item.filterTypeID} updateFilter={updateFilterChoices} filter={store.tempFilters.population} apiFilter={item} clearFilterLabel={clearFilterLabel}><PopulationIcon/></FilterGeneric>  // done !!!
                                case 10:
                                  return <FilterGeneric key={item.filterTypeID} updateFilter={updateFilterChoices} filter={store.tempFilters.campuses} apiFilter={item} clearFilterLabel={clearFilterLabel}><CampusIcon/></FilterGeneric>  // done !!!
                                case 12:
                                  return <FilterGeneric key={item.filterTypeID} updateFilter={updateFilterChoices} filter={store.tempFilters.forGroups} apiFilter={item} clearFilterLabel={clearFilterLabel}><AmountIcon/></FilterGeneric>  // done !!!
                                default:
                                  return '';
                            }
                        })
                    }
                </div>
                {/* <div className='relative filter_cross pointer' onClick={() => clearFilters()}><button type='button' className='clear_filters_btn fa fa-filter'></button></div> */}
            </form>
        </div>
    </section>
    )
}
