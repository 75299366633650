import React from 'react';
import './BannerCard.css';

export default function BannerCard(props) {
  let img_src = props.banner_img_src;	
  let img_alt = props.banner_img_alt;	
  let title = props.banner_title;
  let content = props.banner_content;
  
  return (
    <div className="card-container banner-card">
		<div className="card">
			<div className="card-img"> 
                <img src={img_src} alt={img_alt} aria-hidden="true"/> 
            </div>
			<div className="card-text" style={{background: props.leadingColor}}>
				<h4>{title}</h4>
				<p>{content}</p>
			</div>
		</div>
	</div>
  )
}
